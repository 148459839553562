import Vue from 'vue';
import axios from 'axios';
import dotenv from 'dotenv';

import App from './App.vue';
import router from './router';
import store from './store';
import DefaultLayout from './layouts/Default';
import PageWithNavigationLayout from './layouts/PageWithNavigation';

dotenv.config();

Vue.component('default-layout', DefaultLayout);
Vue.component('page-with-navigation-layout', PageWithNavigationLayout);
Vue.use(require('vue-moment'));

import './assets/styles/index.css';

Vue.config.productionTip = false

Vue.prototype.$api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 10000
});

Vue.prototype.$api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('token');

  if(token) {
    config.headers.common.Authorization = `Bearer ${token}`;
  }
  
  return config;
}, (error) => {
  throw error;
});

const app = new Vue({
  router,
  store,
  render: h => h(App)
});

store.$app = app;
app.$mount('#app');