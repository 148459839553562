<script>
import { Doughnut, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Doughnut,
  props:['chartData','options'],
  mixins: [reactiveProp],
  methods: {
    update() {
      this.$data._chart.update()
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.options)
  },
  watch: {
    chartData: function() {
      this._data._chart.destroy();
      this.renderChart(this.chartData, this.options);
    }
  }
};
</script>