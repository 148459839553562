<template>
    <div class="px-2 space-y-2">
        <router-link :to="{name: 'Dashboard'}" class="inline-flex items-center justify-center px-4 py-2 shadow-sm text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-700 focus:outline-none mt-2 w-full">
            Dashboard
        </router-link>
        <button @click="exportData" type="button" class="inline-flex items-center justify-center px-4 py-2 shadow-sm text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-700 focus:outline-none w-full">
            Export
        </button>
        <button @click="logout" type="button" class="inline-flex items-center justify-center px-4 py-2 shadow-sm text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-700 focus:outline-none  w-full">
            Logout
        </button>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters([
            'getFilters',
        ]),
    },
    methods: {
      async exportData() {
          let queryString = Object.keys(this.getFilters).map(key => key + '=' + this.getFilters[key]).join('&');
          window.open(process.env.VUE_APP_API_URL + "/export/?" + queryString)
      },
      async logout() {
        await this.$store.dispatch('logout');
        await this.$router.push('/signin');
      }
    },
}
</script>
<style scoped>
.router-link-exact-active {
    background: rgba(75, 85, 99);
}
</style>