<template>
    <div class="bg-gray-800 overflow-hidden shadow rounded-lg">
        <div class="p-5">
            <div class="flex items-center">
                <div class="ml-5 w-0 flex-1">
                    <dl>
                        <dt class="text-sm font-medium text-gray-400 truncate">
                            {{title}}
                        </dt>
                        <dd>
                            <div class="text-2xl font-medium mt-3" :style="{color: color}">
                                {{value}}
                            </div>
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: ['title', 'value', 'color']
    }
</script>
<style>

</style>